export default question => {
  const optionLetterMap = {
    0: "A",
    1: "B",
    2: "C",
    3: "D",
    4: "E",
    5: "F"
  };
  switch (question.test_type) {
    case "toefl":
      return {
        ...question,
        answers: question.answers.map(answer => ({
          ...answer,
          answers: [...answer.options]
        })),
        options: question.options.map(option => ({
          ...option,
          letter: option.title[0],
          title: option.title.slice(3)
        }))
      };
    default:
      return question;
  }
};
